@charset "utf-8";

@use 'sass:math';

@import '../node_modules/bulma/sass/utilities/initial-variables';
@import '../node_modules/bulma/sass/utilities/derived-variables';

$block-list-separator: 0.25rem !default;
$block-list-highlight-width: 5px !default;

.content {
  ul.block-list {
    list-style: none;
    margin-left: 0;
  }
}

.block-list {
  list-style: none;

  li {
    padding: math.div($gap, 2);
    background: var(--bulma-body-background-color);
    margin-bottom: $block-list-separator;
  }

  li.is-left,
  &.is-left > li {
    text-align: left;
  }

  li.is-centered,
  &.is-centered > li {
    text-align: center;
  }

  li.is-right,
  &.is-right > li {
    text-align: right;
  }

  li.is-small,
  &.is-small > li {
    font-size: $size-7;
    padding: math.div($gap, 3);
  }

  li.is-normal,
  &.is-normal > li {
    font-size: $size-6;
  }

  li.is-large,
  &.is-large > li {
    font-size: $size-5;
  }

  li.has-radius,
  &.has-radius > li {
    border-radius: $radius;
  }

  li.is-highlighted,
  &.is-highlighted > li {
    border-left: $block-list-highlight-width var(--bulma-dark) solid;
  }

  @mixin outlined($color) {
    background: transparent;
    color: var(--bulma-#{$color});
    border: 1px solid var(--bulma-#{$color});
  }

  li.is-outlined,
  &.is-outlined > li {
    @include outlined('light');
  }

  li.has-icon {
    display: flex;

    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      margin-right: 1em;
    }

    span {
      flex-grow: 1;
    }

    &.is-right {
      .icon {
        margin-right: 0;
        margin-left: 1em;
      }
    }
  }

  @each $name, $pair in $colors {
    li.is-#{$name},
    &.is-#{$name} > li {
      background: var(--bulma-#{$name});
      color: var(--bulma-#{$name}-invert);
    }

    li.is-#{$name}.is-outlined {
      @include outlined($name);
    }

    li.is-#{$name}.is-highlighted {
      @include outlined($name);
      border-left: $block-list-highlight-width var(--bulma-#{$name}) solid;
    }

    &.is-#{$name} {
      li.is-outlined,
      &.is-outlined > li {
        @include outlined($name);
      }

      li.is-highlighted,
      &.is-highlighted > li {
        @include outlined($name);
        border-left: $block-list-highlight-width var(--bulma-#{$name}) solid;
      }
    }
  }
}
